.carousel {

    &.swiper-container {
        @apply overflow-visible;
    }

    .swiper-slide {

        //img {
        //    opacity: 1;
        //}

        > div > div {
            @apply opacity-0;
        }

        &.swiper-slide-active,
        &.swiper-slide-duplicate-active {
            @apply shadow-2xl;

            img {
                @apply opacity-100;
            }

            > div > div {
                @apply opacity-100;
            }
        }
    }
}